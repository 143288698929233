import React, { useState, useEffect } from "react";
import Cplus from "../../SubComponent/Cplus";
import EditIcon from "../../SubComponent/EditIcon";
import EmailIcon from "../../SubComponent/EmailIcon";
import PhoneIcon from "../../SubComponent/PhoneIcon";
import Button from "../../Button/Button";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import RadioButton from "../../SubComponent/AllSvgs/RadioButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import AccountFooter from "../AccountFooter";
import "./SummaryPageSampleStyles.css";
import {
  closeIcon,
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  skipIcon,
  tickIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";

const ManageAddressPart = () => {
  const [activeAddress, setActiveAddress] = useState(0); // for the specified address to show in summary page
  const [isSmallScreen] = useState(window.innerWidth <= 992);
  const navigate = useNavigate();
  const [showPopupforCart, setShowPopupforCart] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const closePopup = () => {
    setShowPopupforCart(false);
  };

  const handleEditAddressClick = () => {
    setShowPopupforCart(true);
  };


  //for choosing the selected index
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(
    parseInt(localStorage.getItem('selectedAddressIndex'), 10) || 0
  );


  //choosing the radio button
  const handleCheckboxChange = (index) => {
    console.log(`Selected index: ${index}`); // Log the index to the console
  
    // Find the selected address based on the index
    const selectedAddress = data[index];
  
    if (selectedAddress) {
      // Log the details of the selected address
      console.log(`${selectedAddress.firstName} ${selectedAddress.lastName}  ${selectedAddress.streetAddress1} ${selectedAddress.streetAddress2} ${selectedAddress.city}  ${selectedAddress.countryArea}  ${selectedAddress.postalCode}` );
      console.log(` ${selectedAddress.shipping_address.streetAddress1}  ${selectedAddress.shipping_address.streetAddress2} ${selectedAddress.shipping_address.city}  ${selectedAddress.shipping_address.countryArea}  ${selectedAddress.shipping_address.postalCode}` );
    } else {
      console.log('No address found for this index.');
    }
  
    setSelectedAddressIndex(index);
    localStorage.setItem('selectedAddressIndex', index.toString());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response?.data?.user?.addresses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;
  console.log("fetch manage address===>>>", data);

  const handleDeliverHere = () => {
    navigate('/summaryCartPage');
  };

  const onRadioHandler = (index) => {
    setActiveAddress(index);
    localStorage.setItem('selectedAddressIndex', index);
  };

  return (
    <>
      <div className="container-fluid pt-4">
        <div className="totalcartpagebuttop">
          <div className="centertotaldivcart">
            <div className="icon-container">
              <span className="dilevery-address-1">
                {ellipseWhiteIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">1</span>
            </div>
            <span className="add-to-cart-text">Add to Cart</span>
            <div className="line lineedbr">
              {vectorLineIcon({ width: 88, height: 1 })}
            </div>
            <div className="icon-container rsbbref">
              <span className="order-sumary-2">
                {ellipsePurpleIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">2</span>
            </div>
            <span className="add-to-cart-text">Delivery Address</span>
            <div className="line lineedbr">
              {vectorLineIcon({ width: 88, height: 1 })}
            </div>
            <div className="icon-container">
              <span className="order-sumary-2">
                {ellipseWhiteIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">3</span>
            </div>
            <span className="add-to-cart-text">Payment</span>
          </div>
        </div>
        <div className={`${isSmallScreen ? "container-fluid" : "container"} pt-5`}>
          {data?.length >= 1 ? (
            <div className="row">
              <div
                className={`col col-md-12 ${isSmallScreen ? "pt-4" : "p-5"} bg-F9F9F9`}
              >
                <div className="row pt-3 justify-content-between">
                  <div className="col col-md-6 col-12 heading-600-24-20">
                    Select a Delivery Address
                  </div>
                  <div
                    className={`col col-md-4 col-12 ${isSmallScreen ? "pt-3" : ""}`}
                  >
                    <div
                      className="d-flex justify-content-end curser-pointer"
                      onClick={() =>
                        navigate("/addaddresscart", {
                          state: {
                            cancelNavi: "/manageaddresspart",
                            saveNavi: "/manageaddresspart",
                            status: "Add Address",
                          },
                        })
                      }
                    >
                      <Cplus />
                      <p className="m-0 pl-2 heading-600-14">Add New Address</p>
                    </div>
                  </div>
                </div>
                {data.map((add, index) => (
                  <div
                    key={index}
                    className="row justify-content-between pt-4"
                  >
                    <div className="col col-lg-7 col-md-9 col-12">
                      <div
                        className="d-flex align-items-center"
                        onClick={() => handleCheckboxChange(index)}
                      >
                        <RadioButton
                          w={24}
                          h={24}
                          fInner={
                            selectedAddressIndex === index
                              ? "#73509E"
                              : "none"
                          }
                          fOuter={
                            selectedAddressIndex === index
                              ? "#73509E"
                              : "#AAAAAA"
                          }
                          checked={selectedAddressIndex === index}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <p className="pl-3 heading-600-20 m-0">
                          {`${add.firstName} ${add.lastName}`}
                        </p>
                      </div>
                      <div
                        className="pt-2 heading-400-14 op-80"
                        style={{ paddingLeft: "39px" }}
                      >
                       {(add.billing_address || add.shipping_address) && (
  <>
    {add.billing_address && (
      <div>
        <strong>Billing Address:</strong>
        <div>
          {add.billing_address.streetAddress1} ,{" "}
          {add.billing_address.streetAddress2} ,{" "}
          {add.billing_address.city} ,{" "}
          {add.billing_address.countryArea}{" "}
          {add.billing_address.postalCode}
        </div>
        <br />
      </div>
    )}
    {add.shipping_address && (
      <div>
        <strong>Shipping Address:</strong>
        <div>
          {add.shipping_address.streetAddress1} ,{" "}
          {add.shipping_address.streetAddress2} ,{" "}
          {add.shipping_address.city} ,{" "}
          {add.shipping_address.countryArea}{" "}
          {add.shipping_address.postalCode}
        </div>
        <br />
      </div>
    )}
  </>
)}

                      </div>
                      <div
                        className="pt-2"
                        style={{ paddingLeft: "39px" }}
                      >
                        <div className="row pb-4">
                          <div className="col col-lg-4 col-12 pr-0">
                            <div className="d-flex">
                              <PhoneIcon />
                              <p className="heading-400-16-14 op-80">
                                {add.phone}
                              </p>
                            </div>
                          </div>
                          <div className="col col-lg-8 col-12">
                            <div className="d-flex">
                              <EmailIcon />
                              <p className="heading-400-16-14 op-80">
                                {add?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col col-lg-5 col-md-3 col-12 d-flex justify-content-end"
                    >
                      <div
                        className="d-flex curser-pointer"
                        onClick={() =>
                          navigate(`/addaddresscart?AddressId=${add?.id}`, {
                            state: {
                              cancelNavi: "/manageaddresspart",
                              saveNavi: "/manageaddresspart",
                              status: "Edit Address",
                            },
                          })
                        }
                      >
                        <EditIcon />
                        <p className="m-0 pl-2 heading-600-14">
                          Edit Address
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <><div className="row">
                <div
                  className="col col-md-12 pt-5 d-flex justify-content-center align-items-center"
                >
                  <p className="heading-600-24">No addresses found</p>


                </div>

              </div><button
                className="unique-submitbtn gap-2 mb-3"
              style={{marginLeft:"42%"}}
              onClick={() =>
                navigate("/addaddresscart", {
                  state: {
                    cancelNavi: "/manageaddresspart",
                    saveNavi: "/manageaddresspart",
                    status: "Add Address",
                  },
                })
              }>
                  Add new Address
                </button></>
          )}
          <div className="row">
            <div
              className="col col-md-12 d-flex justify-content-end pt-4"
            >
              <button
                        className="unique-submitbtn gap-2 mb-3"
                        onClick={handleDeliverHere}
                      >
                        Deliver Here
                      </button>
            </div>
          </div>
        </div>
      </div>
      <AccountFooter />
    </>
  );
};

export default ManageAddressPart;

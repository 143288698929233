import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";
import Footer from "../../Footer/Footer";
import Confirmation from "../../Buying/Product/Confirmation";
import axios from "axios";

const SummaryCartPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const [orderId, setOrderId] = useState('');
  const address = location.state?.address || {}; // Ensure address is an object
  const [loginPortal, setLoginPortal] = useState(false);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const id = queryParams.get('id');
  const message = queryParams.get('message');
    const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [draftItems, setDraftItems] = useState([]);
  const [quantity, setQuantity] = useState("");

  const selectedAddressIndex =
    parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;

  const selectedAddress = data ? data[selectedAddressIndex] : null;

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      try {
        console.log('Fetching order details for:', { customerId, referenceNumber });

        const response = await fetch(`https://ompextension.origa.market/sparesapi/get_orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customerId,
            reference_number: referenceNumber,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        console.log('API response:', data);

        if (data.order) {
          setOrderDetails(data.order);

          // Extract the s3_url from the invoice_info
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false);
      }
    };

    const id = localStorage.getItem("id");
    if (id && message === 'Paid') {
      fetchOrderDetails(id);
    }
    
  }, [id, message]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
          }
        );
        setData(response?.data?.response?.data?.user?.addresses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [responseUrl, setResponseUrl] = useState("");

  console.log(
    "selectedAddress-------------------------------->",
    selectedAddress
  );

  const handleProceedConfirmation = async () => {
    const query = `
          mutation CreatePayment($inputpayment: PaymentInput!) {
            createPayment(inputpayment: $inputpayment) {
              payment {
                merchantTransactionId
                PaymentID
              }
              message
              success
              responseurl
            }
          }
      `;

    const variables = {
      inputpayment: {
        amount: "10",
        createdby: "Elango",
        mobileno: "9943494562",
        paymentmethod: "PAGE",
        paymentstatus: "PEN",
        redirecturl:
          `https://ompextension.origa.market/toolspaymentredirect?id=${orderId}`,
      },
    };

    try {
      const response = await fetch(
        "https://ompextension.origa.market/graphql/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        }
      );

      const result = await response.json();
      if (
        result.data &&
        result.data.createPayment &&
        result.data.createPayment.responseurl
      ) {
        setResponseUrl(result.data.createPayment.responseurl);
        window.location.href = result.data.createPayment.responseurl;
      } else {
        console.error("Failed to get response URL:", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName") ;
      const customer_last_name = localStorage.getItem("lastName") ;
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId");

      if (loggedin) {
        const requestBody = {
          items: draftItems.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          })),
          ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "",
        };
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }

          const draftData = await draftResponse.json();
          setOrderId(draftData.order_id  || 0);
          setCartItems(draftData.line_items || []);
          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0);
          setQuantity(draftData.total_quantity || 0);

          // Calculate the total quantity from the response
          const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          setTotalQuantity(totalQuantity);
        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);

  const handleProceedPaymentbtn = () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      handleCreateOrder();
      navigate("/manageaddresspart");
    } else {
      setLoginPortal(true);
    }
  };

  const handleCreateOrder = async () => {
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      // Handle case where user is not logged in
      setLoginPortal(true); // Assuming this sets state to show login portal
      return;
    }
    // console.log('cartItems --------------------------------------------------------------- > :', cartItems);
    // console.log("cartItems -------------------------------->  ",cartItems)
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("localCartItems :: ", localCartItems);

    var itemList = [];
    for (var item in localCartItems) {
      var obj = {};
      obj["item_id"] = localCartItems[item]["productId"];
      obj["quantity"] =  localCartItems[item]["quantity"];
      itemList.push(obj);
    }
    console.log("itemList -------------------->  :: ", itemList);
    const ompuserId = localStorage.getItem("id") || "defaultId";
    const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
    const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
    const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
    const customer_email = localStorage.getItem("emailId") || "default@example.com";
  
    


    const requestBody = {
      items: itemList,
      ompuserId,//get the id from the localStorage
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: "Draft",
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "IN",
        countryArea: "",
        firstName: "",
        lastName: "  ",
        phone: "",
        postalCode: "",
        streetAddress1: " ",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: " ",
        country: "IN",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "27AABCU9603T1ZV",
    };
     

    try {
      console.log(
        "requestBody  ------------------------------------------------------------- > :: ",
        requestBody
      );
      // Create order request
      const createOrderResponse = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!createOrderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const createOrderData = await createOrderResponse.json();
      const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
      console.log("Created order with items:", draftItems);

      // Clear local storage cartItems after successful order creation
      // localStorage.removeItem('cartItems');
      setCartItems([]); // Clear cart items state
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };


  //To fetch the customer addresses

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response?.data?.user?.addresses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  return (
    <>
      <div className="totalcartpagebuttop">
        <div className="centertotaldivcart">
          <div className="icon-container">
            <span className="dilevery-address-1">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-container rsbbref">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-container ">
            <span className="order-sumary-2">
              {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>
      </div>

      <div class="row justify-content-center twodivborderseperate">
        <div className="DeliveryAddressesHeading">
          {/* <h1 className='DeliverAddresContentText'>Your Delivery Address : </h1> */}
        </div>
        <div class="col-6 leftsideborderdeliverypart">
          <h4 className="addressheading"> Address : </h4>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartbottomiin" />
          </div>

          <p>
            <div></div>

            {selectedAddress && (
              <>
                <span className="headingindelivery">Name : </span>
                {selectedAddress.firstName} {selectedAddress.lastName}
                <br></br>
                <span className="headingindelivery">Mobile Number : </span>
                {selectedAddress.phone}
                <br></br>
                <span className="headingindelivery">Billing Address: </span>
                <br></br>
                {selectedAddress.streetAddress1}, {selectedAddress.city},
                <br></br>
                {selectedAddress.countryArea}, {selectedAddress.postalCode}
                <br></br>
                <span className="headingindelivery">Shipping Address: </span>
                <br></br>
                {selectedAddress.shipping_address.streetAddress1},{" "}
                {selectedAddress.shipping_address.city},<br></br>
                {selectedAddress.countryArea},{" "}
                {selectedAddress.shipping_address.postalCode}
                <br></br>
              </>
            )}
          </p>
        </div>

        {/* <div class="col-8 leftsideborderdeliverypart">
    <h4>Your Products  : </h4>

    <div className="horizontallineofcartpage">
            <hr className="lineofcartbottomiin" />
          </div>




      </div> */}
        <div class="col-4 rightsidebordersummarypart">
          <div className="buttondivsummaryaddress">
            <button
              className="usethisaddressinbutton "
              onClick={handleProceedConfirmation}
            >
              Proceed
            </button>

            <p className="chooseashippingaddress">
              Choose a shipping address and payment method to calculate
              shipping, handling and tax.
            </p>
            <hr className="horinvdnv" />
            {/* <hr className="lineofcartbottomiinvjnbf" /> */}
            <h6 className="ordersummaryheading">Order Summary</h6>
            <div className="pricing-details">
              <div className="pricing-item">
                <span className="item-label">Total:</span>
                <span className="item-value">₹{subtotal}</span>
              </div>
              <div className="pricing-item">
                <span className="item-label">Sub Total:</span>
                <span className="item-value">₹{subtotal}</span>
              </div>
              <div className="pricing-item">
                <span className="item-label">Shipping Charges</span>
                <span className="item-value">₹{shippingCharge}</span>
              </div>
              <div className="pricing-item total">
                <span className="item-label">Total quantity:</span>
                <span className="item-value">{totalprice}</span>
              </div>
              <div className="pricing-item promotion">
                <span className="item-label">Discount percent:</span>
                <span className="item-value">₹{discountTotal}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SummaryCartPage;

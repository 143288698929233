import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import axios from "axios";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../OrigaExtentionAPI/mutations";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import "./CartPageSampleStyles.css";
import {
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  tickIcon,
  vectorLineIcon,
} from "../../helpers/Icons";
import CartItemSample from "./CartItemSample";
import CartItemSampleLoggedIn from "./CartItemSampleLoggedIn";
import SummaryCartLoggedIn from "./SummaryCartLoggedIn";
import SummaryCartLoggedNot from "./SummaryCartLoggedNot";

const client = secondClient;
const CartPageSample = () => {

  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1); // Change the key to force re-render
  };


  //For retrieving productId from the graphql query
  const [productDETAILS, setProductDETAILS] = useState({});
  const productId = localStorage.getItem("productIdtoCart");
  const groupId = localStorage.getItem("groupIdtoCart");

  //To get the response data and set it to a variable
  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [total, setTotal] = useState(0);


  //to see whether the user is logged in or not
  const [loginPortal, setLoginPortal] = useState(false);
  const [loggedin, setLoggedin] = useState(!!localStorage.getItem("userToken"));
  const onHidePortal = () => {
    setLoginPortal(false);
  };

  //get the cartItems from the localStorage
  //if no items are not found then intialise cartItems with an empty array
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  //to get the number of items currently in the cart
  const [quantity, setQuantity] = useState("");//cartItems.length
  let draftItems = [];

//to fetch draft items from the api
useEffect(() => {  
  const addCartItems = async () => {
    const loggedin = !!localStorage.getItem("userToken");//see whether the user is logged in
    const localCartItems =
      JSON.parse(localStorage.getItem("cartItems")) || [];//retrieve the cartItems that has been pushed
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName") ;
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId");
    if (loggedin) {

      const requestBody = {
        items: [], // Initially empty as we'll fetch from API
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
      };

      // try {
      //   // Fetch draft items using the same api
      //   const draftResponse = await fetch(
      //     "https://ompextension.origa.market/sparesapi/create_order",
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(requestBody),
      //     }
      //   );

      //   if (!draftResponse.ok) {
      //     throw new Error("Failed to fetch draft items");
      //   }

      //   //get the response of this api and save this response in draftData
      //   const draftData = await draftResponse.json();
      //   setCartItems(draftData.line_items || []);//In the cartItems variable you are setting the response items
      //   setSubtotal(draftData.subtotal || 0);//to calculate to total of the cart
      //   setTaxTotal(draftData.tax_total || 0);
      //   setDiscountTotal(draftData.discount_total || 0);
      //   setDiscountPercent(draftData.discount_percent || 0);
      //   setShippingCharge(draftData.shipping_charge || 0);
      //   setTotal(draftData.total || 0);
      //   setTotalPrice(draftData.total_quantity || 0);
      //   //Store these lineitems in draftItems
      //   let draftItems = draftData.line_items || []; // draft items are in line_items array so now draftItems now holds the response items from the api

      //   // set the draftItems value to cartItems
      //   setCartItems(draftItems); //So now cartItems holds all the items that is draft in the api
      // } catch (error) {
      //   console.error("Error fetching or merging cart items:", error);
      // }
    } else {
       // set the localCartItems value to cartItems
      setCartItems(localCartItems); // Use local cart items if not logged in
    }
  };

  addCartItems(); // Call the function to add cart items on component mount
}, []);//ensures the effect runs only once after the initial render.




//to create an order
  const handleCreateOrder = async () => {
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      setLoginPortal(true);
      return;
    }
    console.log('cartItems --------------------------------------------------------------- > :', cartItems);
    console.log('cartItems --------------------------------------------------------------- > :', cartItems);
    console.log('cartItems --------------------------------------------------------------- > :', cartItems);
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("localCartItems  --------------------------------------------------------------- > :' ", localCartItems);

    var itemList = []; //create an array to represent items in the cart
    for (var item in localCartItems) {//iterate the list of items in cartItems in LocalStorage
      var obj = {};
      obj["item_id"] = localCartItems[item]["productId"];
      obj["quantity"] = quantity;
      itemList.push(obj);
    }
    console.log("itemList -------------------->  :: ", itemList);
    //Get all this localStorage
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName") ;
    const customer_contact_no = localStorage.getItem("number") ;
    const customer_email = localStorage.getItem("emailId");
  
        const requestBody = {
          items: [], // Initially empty as we'll fetch from API
          ompuserId,//get the id from the localStorage
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "27AABCU9603T1ZV",
        };
  
    try {
      console.log(
        "requestBody  ------------------------------------------------------------- > :: ",
        requestBody
      );
      // Create order request for the same api
      const createOrderResponse = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!createOrderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const createOrderData = await createOrderResponse.json();
      const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
      console.log("Created order with items:", draftItems);

      // Clear local storage cartItems after successful order creation
      // localStorage.removeItem('cartItems');
      setCartItems([]); // clear the cart items from the component’s state after a successful order creation.
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  //the graphql query to get the productId 
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const fetchData = async () => {
  //     try {
  //       const { data } = await client.query({
  //         query: GET_SPARE_DETAILS,
  //         variables: { groupId: "", itemId: productId },
  //       });
  //       setProductDETAILS(data?.inventoryItem?.response);
  //     } catch (error) {
  //       console.error("Error fetching product details:", error);
  //     }
  //   };
  //   fetchData();
  // }, [productId]);



  return (
    <>
      <div className="totalcartpagebuttop">
        <div className="centertotaldivcart">
          <div className="icon-container">
            <span className="dilevery-address-1">
              {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-container rsbbref">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-container ">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>
      </div>

      <div className="totalcartpage">
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div>
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

          <div className="">
            {loggedin ? (
              <CartItemSampleLoggedIn cartItems={cartItems}    />
            ) : (
              cartItems.map((item, index) => (
                <CartItemSample
                  key={index}
                  productId={item.productId}
                  groupId={item.groupId}
                  productName={item.productName}
                  productBrand={item.productBrand}
                  productImage={item.productImage}
                  AvailableStock={item.AvailableStock}
                  quantity={item.quantity}
                  salePrice={item.salePrice}
                  handleDecreaseQuantity={() => {}}
                  handleIncreaseQuantity={() => {}}
                />
              ))
            )}
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartbottom" />
          </div>
        </div>

        <div className="rightsideBordertotaldiv">
          {loggedin ? <SummaryCartLoggedIn /> : <SummaryCartLoggedNot />}
         
        </div>
      </div>
      {loginPortal && (
        <LoginModel onHide={onHidePortal} onLoginSuccess={handleCreateOrder} />
      )}
      <Footer />
    </>
  );
};

export default CartPageSample;


import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineDoneOutline } from "react-icons/md";
import Footer from "../Footer/Footer";
import axios from "axios";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../OrigaExtentionAPI/mutations";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import "./CartPageSampleStyles.css";
import { ellipsePurpleIcon, ellipseWhiteIcon, tickIcon, vectorLineIcon } from "../../helpers/Icons";
import CartItem from "./CartItem";
import CartItemSample from "./CartItemSample";
import CartItemSampleLoggedIn from './CartItemSampleLoggedIn';

const client = secondClient;
const SummaryCartLoggedIn = (refresh) => {
    const location = useLocation();
    const [infokey, setInfokey] = useState(0);
    const [productDETAILS, setProductDETAILS] = useState({});
    const [SuccessPopUp, setSuccessPopUp] = useState(false);
    const [PaymentLink, setPaymentLink] = useState([]);
    const navigate = useNavigate();
    const [subtotal, setSubtotal] = useState(0);
    const [taxTotal, setTaxTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [shippingCharge, setShippingCharge] = useState(0);
    const[totalprice,setTotalPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const [quantities, setQuantities] = useState([]);
    const productId = localStorage.getItem("productIdtoCart");
    const groupId = localStorage.getItem("groupIdtoCart");
   
    const [loginPortal, setLoginPortal] = useState(false);
    const [cartItems,setCartItems] = useState(JSON.parse(localStorage.getItem('cartItems')) || []);
    const [quantity, setQuantity] = useState("");
    const uniqueProductIds = [...new Set(cartItems.map(item => item.productId))];
    const [loggedin, setLoggedin] = useState(!!localStorage.getItem('userToken'));
    const [totalQuantity, setTotalQuantity] = useState(0);   
  
    // Count the number of unique productIds
    const totalUniqueItems = uniqueProductIds.length;
    console.log(totalUniqueItems);
    
    const onHidePortal = () => {
      setLoginPortal(false);
  }
  
  
  let draftItems = [];
  const totalPrice = cartItems.reduce((total, item) => total + (item.salePrice * item.quantity), 0);
  
 

  // Function to handle increasing quantity
  const handleIncreaseQuantity = async (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;

    const ompuserId = localStorage.getItem("id") || "defaultId";
    const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
    const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
    const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
    const customer_email = localStorage.getItem("emailId") || "default@example.com";
    const city = localStorage.getItem("city") || "DefaultCity";
    const cityArea = localStorage.getItem("cityArea") || "";
    const companyName = localStorage.getItem("companyName") || "DefaultCompanyName";
    const country = localStorage.getItem("country") || "IN";
    const countryArea = localStorage.getItem("countryArea") || "DefaultCountryArea";
    const input_firstName = localStorage.getItem("input_firstName") || "DefaultFirstName";
    const input_lastName = localStorage.getItem("input_lastName") || "DefaultLastName";
    const input_phone = localStorage.getItem("input_phone") || "DefaultPhone";
    const postalCode = localStorage.getItem("postalCode") || "DefaultPostalCode";
    const streetAddress1 = localStorage.getItem("streetAddress1") || "DefaultStreetAddress1";
    const streetAddress2 = localStorage.getItem("streetAddress2") || "";
    const business_city = localStorage.getItem("business_city") || "DefaultCity";
    const business_cityArea = localStorage.getItem("business_cityArea") || "";
    const business_companyName = localStorage.getItem("business_companyName") || "DefaultCompanyName";
    const business_country = localStorage.getItem("business_country") || "IN";
    const business_countryArea = localStorage.getItem("business_countryArea") || "DefaultCountryArea";
    const business_firstName = localStorage.getItem("business_firstName") || "DefaultFirstName";
    const business_lastName = localStorage.getItem("business_lastName") || "DefaultLastName";
    const business_phone = localStorage.getItem("business_phone") || "DefaultPhone";
    const business_postalCode = localStorage.getItem("business_postalCode") || "DefaultPostalCode";
    const business_streetAddress1 = localStorage.getItem("business_streetAddress1") || "DefaultStreetAddress1";
    const business_streetAddress2 = localStorage.getItem("business_streetAddress2") || "";
    const gst_no = localStorage.getItem("gst_no") || "27AABCU9603T1ZV";
    

    // Update the state
    setCartItems(updatedCartItems);

    // Prepare the request body
    const requestBody = {
      items: updatedCartItems.map(item => ({
        item_id: item.item_id,
        quantity: item.quantity
      })),
      ompuserId,
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city,
            cityArea,
            companyName,
            country,
            countryArea,
            firstName: input_firstName,
            lastName: input_lastName,
            phone: input_phone,
            postalCode,
            streetAddress1,
            streetAddress2,
          },
          businessaddress: {
            city: business_city,
            cityArea: business_cityArea,
            companyName: business_companyName,
            country: business_country,
            countryArea: business_countryArea,
            firstName: business_firstName,
            lastName: business_lastName,
            phone: business_phone,
            postalCode: business_postalCode,
            streetAddress1: business_streetAddress1,
            streetAddress2: business_streetAddress2,
          },
          gst_no
        };
    try {
      await fetch('https://ompextension.origa.market/sparesapi/create_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      console.log("Quantity of this item id :  ", updatedCartItems[index].item_id);
      console.log("Quantity of this item quantity :  ", updatedCartItems[index].quantity);
    } catch (error) {
      console.error('Error updating quantity:', error);
      // Optionally, revert the state update in case of an error
    }
  };

  // Function to handle decreasing quantity
  const handleDecreaseQuantity = async (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 0) {
      updatedCartItems[index].quantity--;

      const ompuserId = localStorage.getItem("id") || "defaultId";
      const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
      const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
      const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
      const customer_email = localStorage.getItem("emailId") || "default@example.com";
      
      

      // Update the state
      setCartItems(updatedCartItems);

      // Prepare the request body
      const requestBody = {
        items: updatedCartItems.map(item => ({
          item_id: item.item_id,
          quantity: item.quantity
        })),
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
      };
      try {
        await fetch('https://ompextension.origa.market/sparesapi/create_order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        console.log("Quantity of this item id :  ", updatedCartItems[index].item_id);
        console.log("Quantity of this item quantity :  ", updatedCartItems[index].quantity);
      } catch (error) {
        console.error('Error updating quantity:', error);
        // Optionally, revert the state update in case of an error
      }
    }
  };

  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id") || "defaultId";
      const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
      const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
      const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
      const customer_email = localStorage.getItem("emailId") || "default@example.com";

      if (loggedin) {
        const requestBody = {
          items: draftItems.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          })),
          ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
        };
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }

          const draftData = await draftResponse.json();
          setCartItems(draftData.line_items || []);
          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0);
          setQuantity(draftData.total_quantity || 0);

          // Calculate the total quantity from the response
          const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          setTotalQuantity(totalQuantity);
        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);
      
      // Function to merge local items with draft items
      const mergeItems = (localItems, draftItems) => {
        const mergedItems = [...draftItems];
      
        localItems.forEach((localItem) => {
          const foundIndex = mergedItems.findIndex((item) => item.item_id === localItem.item_id);
          if (foundIndex !== -1) {
            mergedItems[foundIndex].quantity += localItem.quantity;
          } else {
            mergedItems.push(localItem);
          }
        });
      
        return mergedItems;
      };
    const handleProceedPaymentbtn = () => {
      const loggedin = !!localStorage.getItem('userToken');
      if (loggedin) {
        handleCreateOrder();
        navigate('/manageaddresspart');
      } else {
        setLoginPortal(true);
      }
    };
    

    const handleCreateOrder = async () => {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) {
        // Handle case where user is not logged in
        setLoginPortal(true); // Assuming this sets state to show login portal
        return;
      }
      // console.log('cartItems --------------------------------------------------------------- > :', cartItems);
      // console.log("cartItems -------------------------------->  ",cartItems)
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      console.log("localCartItems :: ", localCartItems);
      
      var itemList=[];
      for(var item in localCartItems){
          var obj={};
          obj["item_id"] = localCartItems[item]["productId"];
          obj["quantity"] = quantity;
          console.log("quantity--------------------------> of the item id -------------->",quantity );
          itemList.push(obj);
      }
      console.log("itemList -------------------->  :: ", itemList );
  
      const ompuserId = localStorage.getItem("id") || "defaultId";
      const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
      const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
      const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
      const customer_email = localStorage.getItem("emailId") || "default@example.com";
    
      
      const requestBody = {
  
        items: itemList,
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
        };
         
    
      try {
        console.log("requestBody  ------------------------------------------------------------- > :: ", requestBody);
        // Create order request
        const createOrderResponse = await fetch(
          'https://ompextension.origa.market/sparesapi/create_order',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );
    
        if (!createOrderResponse.ok) {
          throw new Error('Failed to create order');
        }
    
        const createOrderData = await createOrderResponse.json();
        const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
        setQuantity(draftItems.total_quantity || 0);
        console.log('Created order with items:', draftItems);
    
        // Clear local storage cartItems after successful order creation
        // localStorage.removeItem('cartItems');
        // setCartItems([]); // Clear cart items state
      } catch (error) {
        console.error('Error creating order:', error);
      }
    };




  
    // useEffect(() => {
    //   window.scrollTo(0, 0);
    //   const fetchData = async () => {
    //     try {
    //       const { data } = await client.query({
    //         query: GET_SPARE_DETAILS,
    //         variables: { groupId: "", itemId: productId },
    //       });
    //       setProductDETAILS(data?.inventoryItem?.response);
    //     } catch (error) {
    //       console.error("Error fetching product details:", error);
    //     }
    //   };
    //   fetchData();
    // }, [productId]);
  
    const handleModal = async () => {
      const loggedIn = !!localStorage.getItem("userToken");
      if (!loggedIn) {
        navigate("/login");
        return;
      }
  
      navigate(`/buy/add-address?id=${productId}&sparetools=true`);
    };
  
    const productName = productDETAILS[0]?.name || "N/A";
    const productBrand = productDETAILS[0]?.brand || "N/A";
    const productImage =
      productDETAILS[0]?.images?.productImages[0]?.url || "N/A";
    const salePrice = productDETAILS[0]?.saleprice || 0;
    const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";

  return (
    <div>
 <div className="rifhtsidebortertotaldivcontent">
          <p>
            <div class="meter-container">
              <div class="meter">
                <div class="meter-fill" style={{ width: "80%" }}></div>
              </div>
            </div>

            <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
            <span className="orderdetailscart">Your order is ready for processing.{" "}</span><br></br>
          </p>
          <p className="summaryContent">
            <span className="summaryItem">
              <span className="label labledddd">Summary</span>
            </span>
            <span className="summaryItem">
              <span className="label">Total quantity:</span>
              <span className="value">{totalQuantity}</span>
            </span>
            <span className="summaryItem">
              <span className="label">MRP Value :</span>
              <span className="value"> ₹{subtotal }</span>
            </span>
            <span className="summaryItem">
              <span className="label">Our Price:</span>
              <span className="value"> ₹{subtotal}</span>
            </span>
            {/* <span className="summaryItem">
              <span className="label">Shipping Charge:</span>
              <span className="value">₹{shippingCharge}</span>
            </span>
          
            <span className="summaryItem">
              <span className="label">Discount :</span>
              <span className="value">₹{discountTotal}</span>
            </span> */}
          </p>
        </div>
        <div className="proceddcartbuttondiv">
          <button className="proceedcartbtn" onClick={handleProceedPaymentbtn}>Proceed to Payment</button>
        </div>

    </div>
  )
}

export default SummaryCartLoggedIn
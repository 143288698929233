import React, { useEffect } from "react";
import "./CardStyles.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Card = ({ imgSrc, label, heading, text , logoSrc,slug, index}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const urls = [
    "/resources/casestudy/origa-innovative-leasing-strategy-yasho-industires",
    "/resources/casestudy/origa-innovative-leasing-strategy-manipal-origa",
    "/resources/casestudy/origa-innovative-leasing-strategy-karkinos-healthcare",
  ];

    return (

    <> 
    
 
      <div className="card">
        <div className="top-part">
          <img src={imgSrc} alt="Card image" />
          <p className="card-label">{label}</p>
          {/* <div className="titlelogo-top-part">
            <img className="titlelog-img-part" src={logoSrc} alt="Logo" />
          </div> */}
        </div>
        <div className="bottom-part">
          <div className="text-bottom-part">
            <h6 className="Heading-bottom-text-part">{heading}</h6>
            <p className="para-text-bottom-part">{text}</p>
          </div>
          <div className="button-bottom-part">
          <Link to={urls[index]}>
            <button className="read-now-btn">Read more</button>
          </Link>
          </div>
        </div>
      </div>


</>
    );
  };
export default Card;


/*

const Card = () => {
  return (
    <>
    <div className="cards">

    
      <div className="card">
        <div className="top-part">
          <img src="https://media.licdn.com/dms/image/sync/D5627AQG_hcsGIXQDHw/articleshare-shrink_800/0/1710948458399?e=2147483647&v=beta&t=UIr9uCB6zEsi5oeZWoz0MNL8rnmUYJZmJwUksckdel0" />
          <div className="titlelogo-top-part">
                <img src="https://business.adobe.com/in/customer-success-stories/media_17e40881b9c11066aa31d79c4984e8f1db979c5c5.png" />
          </div>
        </div>
        <div className="bottom-part">
          <div className="text-bottom-part">
                <h6 className="Heading-bottom-text-part">Cathay United Bank grows mobile app visitors by 71%</h6>
                <p  className="para-text-bottom-part">Cathay United Bank elevates customer-centric approach with Adobe Analytics and Target.</p>
          </div>
          <div className="button-bottom-part">
            <button className="read-now-btn">Read now</button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};


*/
import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon } from "../../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import CustomPagination from "../CustomPagination";
import { secondClient, GET_SPARE_DETAILS } from '../../../OrigaExtentionAPI/mutations'

//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const client = secondClient
const CustomHits = ({ setLoading, hits, Sorting }) => {
    setLoading(false)
    const [productDETAILS, setProductDETAILS] = useState({});
    const [quantity, setQuantity] = useState(1);
    const queryParams = new URLSearchParams();
    const [loginPortal, setLoginPortal] = useState(false);
    const productId = queryParams.get('id');//to get the id from url
    const groupId = queryParams.get('group_id');//to get the id from url
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
    const [infokey, setinfokey] = useState(0);
    const productIdtoCart = productDETAILS?.[infokey]?.id;
    // console.log('productId---->', productId);
    const [deliveryLocation, setDeliveryLocation] = useState("");
    const [buymachineId, setBuymachineId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const handleBuyNow = async (productIdtoCart, quantity) => {
        const loggedin = !!localStorage.getItem("userToken");
        if (!loggedin) {
          setLoginPortal(true);
          return;
        }
       
        const productname = productDETAILS?.[infokey]?.name;
        const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
        const productimage =productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
        const saleprice = productDETAILS?.[infokey]?.saleprice ;
    
        const userToken = localStorage.getItem("userToken");
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        console.log("Now HandleBuyNow inside the /store page  is ongoing");
        const isProductInCart = cartItems.some(
          (item) => item.productId === productIdtoCart
        );
    
        if (!isProductInCart) {
          cartItems.push({
            productId: productIdtoCart,
            productName: productname,
            productBrand: productbrand,
            productImage: productimage,
            salePrice: saleprice,
            quantity: quantity, // Add quantity to the cart item
          });
    
          if (userToken) {
            try {
              var itemList = [];
              for (var item of cartItems) {
                itemList.push({
                  item_id: item.productId,
                  quantity: item.quantity || 1, // Default to 1 if quantity is not set
                });
              } 
             
    
              await fetch("https://ompextension.origa.market/sparesapi/create_order", {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${userToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  items: itemList,
                  ompuserId,//get the id from the localStorage
                  customer_first_name,
                  customer_last_name,
                  customer_contact_no,
                  order_status: "Draft",
                  order_ref_number: "",
                  customer_email,// Replace with actual data
                  input: {
                    city: "",
                    cityArea: "",
                    companyName: "",
                    country: "IN",
                    countryArea: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    postalCode: "",
                    streetAddress1: "",
                    streetAddress2: "",
                  },
                  businessaddress: {
                    city: "",
                    cityArea: "",
                    companyName: "",
                    country: "IN",
                    countryArea: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    postalCode: "",
                    streetAddress1: "",
                    streetAddress2: "",
                  },
                  gst_no: "",
                }),
              });
              console.log("Now HandleBuyNow is ongoing and itemid is added  : ",productIdtoCart);
              console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
            } catch (error) {
              console.error("Error adding item to API cart:", error);
            }
          } else {
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
            console.log(`Item added to localStorage cart. Total items in cart: cartItems.length`);
          }
        } else {
          console.log(`Product with ID ${productIdtoCart} is already in the cart.`);
        }
    
        navigate(`/manageaddresspart`);
      };

      
    const navigate = useNavigate();
    const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
    const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

    const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },

        }));
    };
    
    const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    const findYearDifference = (targetYear) => {
        const currentYear = new Date().getFullYear();
        const yearDifference = currentYear - targetYear;
        return yearDifference;
    }
    const priceConvert = (price) => {
        price = typeof price === 'string' ? price : String(price);


        let count = 1;
        let comma = 3;
        let formatedPrice = ""
        for (let i = price.length - 1; i >= 0; i--) {
            formatedPrice = price[i] + formatedPrice
            if (count === comma) {
                formatedPrice = "," + formatedPrice
                comma = 2;
                count = 0;
            } count++;

        }
        //console.log("==>>", formatedPrice)
        if (formatedPrice[0] === ",") {
            formatedPrice = formatedPrice.slice(1, formatedPrice.length)
        }
        return formatedPrice;



    };
    console.log('filteredHitsToRender---->', filteredHitsToRender);
    useEffect(() => {
        let sortedHits = [...hits];

        if (Sorting !== null) {
            sortedHits = [...hits].sort((a, b) => {
                if (Sorting === "Pricelow_High") {
                    return a["grossPrice"] - b["grossPrice"];
                } else if (Sorting === "PriceHigh_low") {
                    return b["grossPrice"] - a["grossPrice"];
                } else if (Sorting === "AlphabeticallyAtoZ") {
                    return a["productName"].localeCompare(b["productName"]);
                }
                return 0; // Default case
            });
        }

        setFilteredHitsToRender(sortedHits);
    }, [Sorting, hits]);

    const handleFavorite = (index, value) => {
        const updatedHits = [...filteredHitsToRender];
        updatedHits[index]["favorite"] = value;
        setFilteredHitsToRender(updatedHits);
    };

    const onSubmitHandler = (props) => {
        console.log("whish list working", props?.categories.lvl2?.split('>')[2].trim());
        const loggedin = !!localStorage.getItem('userToken');
        if (loggedin) {
            onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
        }
    }

    const handleAvailService = (product) => {
        //SetQuationModel(true)
        if(product?.group_id){
            navigate(`/buy/sparetools?group_id=${product.group_id}`);
        }
        else{
            navigate(`/buy/sparetools?id=${product.objectID}`);
        }
    };

    const handleModal = async (status, obj) => {
        // Check if the user is logged in
        const loggedin = !!localStorage.getItem('userToken');
        if (!loggedin) {
            // If not logged in, set login portal to true and stop further execution
            setLoginPortal(true);
            return;
        }
    
        // If status is 'phone-modal', handle specific logic
        if (status === 'phone-modal') {
            // Check logged in status again (though redundant as already checked above)
            const loggedin = !!localStorage.getItem('userToken');
            if (!loggedin) {
                // If not logged in, set login portal to true and stop further execution
                setLoginPortal(true);
                return;
            } else {
                // Navigate to a specific route with query parameters
                navigate(`/manageaddresspart`);
            }
        }
    
        // If status is truthy (not 'phone-modal'), show modal
        if (status) {
            setShowModal(status);
        } else {
            // If status is falsy, hide modal and perform additional actions
            setShowModal(false);
            const id = localStorage.getItem('id');
            console.log('obj------------>', obj);
            setBuymachineId(obj?.buymachine_id); // Set buy machine ID from obj parameter
            try {
                // Fetch product details using GraphQL query based on productId
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { itemId: productId } });
                setProductDETAILS(data?.inventoryItem?.response); // Update product details state
                updateFormattedPrice(); // Update formatted price based on new product details
            } catch (error) {
                // Handle errors if GraphQL query fails
                console.error('Error fetching product details:', error);
            }
        }
    }
    



    return (
        <>
     
            {filteredHitsToRender?.length > 0 ? (
                <div className="product-wrap">
                    <div className="products">
                        {filteredHitsToRender?.map((product, index) => (
                            <div className="product" key={index} >
                                <div className="product-inner">
                                    <img className="product-img" src={product.thumbnail} alt={product.name} />
                                    <div className="content">
                                        <div className="name">
                                            {product?.productName?.length > 18
                                                ? `${product?.productName?.substring(0, 18)}...`
                                                : product?.productName}
                                        </div>
                                        <div className="location">
                                            {/* {product?.brands} */}
                                            {(() => {
                                                const lvl1Category = product?.categories?.lvl1 || '';
                                                const parts = lvl1Category.split(' > ');
                                                const lastPart = parts[parts.length - 1];
                                                return lastPart;
                                                })()}
                                        </div>
                                        <div className="price-wrap">
                                            <div className="time">{product?.Phase}</div>
                                            {/* <div className="price">₹{product["grossPrice"]}</div> */}
                                            {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                                        </div>
                                        {/* <button className={`heart ${product?.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(product)}>{heartIcon({ width: 25, onClick: () => handleFavorite(index, !product.favorite) })}</button> */}
                                         <div className="buttonsinoneline">
                                         <button className="purchasef pbtnsb" key={index} onClick={() => handleAvailService(product)} type="button">Buy Now</button>
                                       {/*  <button className="purchasef pbtnsb" key={index} onClick={() => handleAvailService(product)} type="button">Add to Cart</button>
                                        <button className="purchasef pbtnsb1" key={index} onClick={() =>  handleBuyNow(productIdtoCart, quantity)} type="button">Buy Now</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <CustomPagination />
                </div>
            ) : (
                <p>No product found.</p>
            )}

        </>
    );
};

export default connectHits(CustomHits);

import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    secondClient,
    GET_SPARE_DETAILS,
  } from "../OrigaExtentionAPI/mutations";
import { useLocation } from 'react-router-dom';
import "./CartPageSampleStyles.css";


  
const client = secondClient;
const CartItemSample = ({  productId,
    productName,
    productBrand,
    productImage,

    salePrice }) => {
    const location = useLocation();//to access the current location
    const [quantity, setQuantity] = useState(() => {
      const savedQuantity = localStorage.getItem('quantity');
      return savedQuantity ? parseInt(savedQuantity, 10) : 1;
    });
    useEffect(() => {
      localStorage.setItem('quantity', quantity);
    }, [quantity]);
    const queryParams = new URLSearchParams(location.search);//to pass the query parameters to the url
    const groupId = queryParams.get('group_id');//to get the id from url
    const [productDETAILS, setProductDETAILS] = useState({});
    const [loading, setLoading] = useState(true);
    const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1); //for increasing
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1)); //for decreasing
  };
  const handleDelete = (productId) => {
    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    cartItems = cartItems.filter(cartItem => cartItem.productId !== productId);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    console.log(`Item with productId ${productId} deleted. Total items in cart: ${cartItems.length}`);
    // Optionally, you can add code here to update the UI
};


    const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },
        }));
    };


    const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    useEffect(() => {
        window.scrollTo(0, 0)// Scrolls the window to the top on component mount or update
        const fetchData = async () => {
            let id = localStorage.getItem('id');
            if (id === null) {
                id = "";
            }
            try {
                 // Performs a GraphQL query using the Apollo client 'client'
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { groupId: groupId || '', itemId: productId || '' } });
                 // Sets the fetched data to state variable 'productDETAILS'
                setProductDETAILS(data?.inventoryItem?.response)
                 // Sets 'loading' state to false after data is fetched
                setLoading(false)
                // Updates formatted price
                updateFormattedPrice()
            } catch (error) {
            }
        }
        fetchData();
    }, [groupId, productId]);


    console.log("Cart Items  :  -------------------------------------->",cartItems);
    

  return (
    <div className="threesectionsofcart">
    <div className="firstsectionofcart">
      <img className="imageonleftofcart" src={productDETAILS[0]?.images?.productImages[0]?.url || "N/A"} alt="Product" />
    </div>
    <div className="secondsectionofcart">
      <h1 className="headingpartofcarttt">
      {productDETAILS[0]?.name || "N/A"}
      {productDETAILS[0]?.brand || "N/A"&& (
          <span className="rbfbef heading-400-20-14 op-60">
            &nbsp;| {productDETAILS[0]?.brand || "N/A"}
          </span>
        )}
      </h1>
      <div className="contentofcartpara">
        <p className="inStocktext">
          <span className="inStocktextcontent">In Stock</span>
          <br></br>
          <span className="stockbold">Stock Quantity </span>: {AvailableStock}
        </p>
      </div>
      <div className="buttonsgapbetweentwocart">
        <span className="quantitytextright">Quantity{" "}</span>
        <div className="quantity-selector-wrapper">
          <div className="quantity-selector">
            <button onClick={handleDecreaseQuantity} className="quantity-button">-</button>
            <span className="quantity-value">{quantity}</span>
            <button onClick={handleIncreaseQuantity} className="quantity-button">+</button>
          </div>
        </div>
        <button className="deletecartbtn"   onClick={() => handleDelete(productId)}>Delete</button>
        
      </div>
    </div>
    <div className="thirdsectionofcart">
      <p className="badgerightside">

        <span className="salespricecart">₹{productDETAILS[0]?.saleprice * quantity|| 0}</span>
      </p>
    </div>

    
    <div className='basespace'></div>
    
  </div>
  

    
  )
}

export default CartItemSample
import React, { useEffect, useState } from 'react';
import { dollerIcon, tagIcon } from '../../../../helpers/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
const MyOrdersPartTwoCode = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSize, setSize] = useState({ width: 38, height: 38 });
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const message = queryParams.get('message');
  const [error, setError] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState('');

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      try {
        console.log('Fetching order details for:', { customerId, referenceNumber });

        const response = await fetch(`https://ompextension.origa.market/sparesapi/get_orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customerId,
            reference_number: referenceNumber,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        console.log('API response:', data);

        if (data.order) {
          setOrderDetails(data.order);

          // Extract the s3_url from the invoice_info
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id && message === 'Paid') {
      fetchOrderDetails("VXNlcjoxNzE=", id);
    }
  }, [id, message]);

  useEffect(() => {
    const fetchOrders = async () => {
      const customer_id = localStorage.getItem("id") || "defaultId";
      console.log(customer_id);
      const requestBody = {
        customer_id // correctly include the customer_id in the request body
      };

      try {
        const response = await fetch('https://ompextension.origa.market/sparesapi/get_orders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }

        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const viewDetailsFunction = (referenceNumber, message) => {
    navigate(`/ordersummarycart?id=${referenceNumber}&message=${message}`);
    console.log("Reference number : ------------------------------------->",referenceNumber);
    console.log("Message   : ------------------------------------->",message);
  };

  return (
    <div className="layout-wrap-machine">
      {orders.map((order, orderIndex) => (
        order.line_items.map((item, itemIndex) => (
          <div key={`${orderIndex}-${itemIndex}`} className="max-container detail-wrap">
            <div className="img-wrap-1">
              <img
                className="img-1"
                src={item.thumbnail?.[0]?.url || "N/A"}
                alt="Product"
              />
            </div>
            <div className="price-wrap">
              <div className="top">
                <div className="right bi-process-wrap">
                  {/* Status 1: Started or Completed */}
                  <span className="completed">{tagIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>

                  {/* Status 2: Started (with additional content) or Completed */}
                  <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "" })}</span>
                  {/* <div className="status-content1 heading-400-12-10">Advance Paid</div> */}

                  {/* Status 3: Started (with additional content) or Completed */}
                  <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "" })}</span>
                  {/* <div className="status-content2 heading-400-12-10">Financing</div> */}

                </div>
              </div>
              <div className="bottom">
                <div className="left-wrap">
                  <div className="name heading-600-20 heading-600-20-16">
                    {item.name}
                  </div>
                  <div className="price">Price: ₹{item.rate}</div>
                  <div className="price">Quantity: {item.quantity}</div>
                  <div className="price">Shipping Charge: ₹{order.shipping_charge}</div>
                </div>
                <div className="right-wrap">
                  <div className="advance-info">
                    <button
                      className="button heading-600-16-14"
                      type="button"
                      onClick={() => viewDetailsFunction(order.reference_id, 'Paid')}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ))}
    </div>
  );
};

export default MyOrdersPartTwoCode;

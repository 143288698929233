import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../OrigaExtentionAPI/mutations";
import Loader from "../SubComponent/Loader";

const client = secondClient;
const CartItemSampleLoggedIn = (item, productId,) => {
  const location = useLocation();

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState();
  const [loginPortal, setLoginPortal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);

  //For retrieving productId from the graphql query
  const queryParams = new URLSearchParams(location.search); //to pass the query parameters to the url
  const groupId = queryParams.get("group_id"); //to get the id from url
  const [productDETAILS, setProductDETAILS] = useState({});

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [total, setTotal] = useState(0);

  const [cartItems, setCartItems] = useState([]);
  const [draftItems, setDraftItems] = useState([]);

  const [quantity, setQuantity] = useState("");
  //to fetch the productID from graphql Query
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const fetchData = async () => {
  //     let id = localStorage.getItem("id");

  //     if (id === null) {
  //       id = "";
  //     }
  //     try {
  //       const { data } = await client.query({
  //         query: GET_SPARE_DETAILS,
  //         variables: { groupId: groupId || "", itemId: productId || "" },
  //       });
  //       setProductDETAILS(data?.inventoryItem?.response);
  //       setLoading(false);
  //     } catch (error) {}
  //   };
  //   fetchData();
  // }, [groupId, productId]);
  
  const loggedin = !!localStorage.getItem('userToken');
  const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const ompuserId = localStorage.getItem("id") ;
  const customer_first_name = localStorage.getItem("firstName") 
  const customer_last_name = localStorage.getItem("lastName") ;
  const customer_contact_no = localStorage.getItem("number");
  const customer_email = localStorage.getItem("emailId") ;


  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName") ;
      const customer_last_name = localStorage.getItem("lastName") ;
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId") ;
  
      if (loggedin) {
        const requestBody = {
          items: [],
          ompuserId,
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "27AABCU9603T1ZV",
        };
        try {
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );
  
          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }
  
          const draftData = await draftResponse.json();
          setCartItems(draftData.line_items || []);
          console.log("draftData.line_items ---------------------->",draftData.line_items );
          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.amount || 0);
          setQuantity(draftData.total_quantity || 0);

  
          const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          setTotalQuantity(totalQuantity);
        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };
  
    const handleCreateOrder = async () => {
      const userToken = localStorage.getItem('userToken');
      if (!userToken) {
        setLoginPortal(true); // Assuming this sets state to show login portal
        return;
      }
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      var itemList = [];
      for (var item in localCartItems) {
        var obj = {};
        obj["item_id"] = localCartItems[item]["productId"];
        obj["quantity"] = 1;
        itemList.push(obj);
      }
  
      const ompuserId = localStorage.getItem("id") ;
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName") ;
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId") ;
  
      const requestBody = {
        items: itemList,
        ompuserId,
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
      };
  
      try {
        const createOrderResponse = await fetch(
          'https://ompextension.origa.market/sparesapi/create_order',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );
  
        if (!createOrderResponse.ok) {
          throw new Error('Failed to create order');
        }
  
        const createOrderData = await createOrderResponse.json();
        const draftItems = createOrderData.line_items || [];
        setTotalPrice(draftItems.amount||0);
        console.log('Created order with items:', draftItems);
        localStorage.removeItem('cartItems');
        setCartItems(draftItems); // Clear cart items state
      } catch (error) {
        console.error('Error creating order:', error);
      }
    };
  
    const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    if (localCartItems.length === 0 && !!loggedin) {
      addCartItems();
      console.log("Add Cart Items function is working ");
    } else {
      handleCreateOrder();
      console.log("Handle Create Order  function is working ");
    }
  }, []);
  
//   
//   useEffect(() => {
//     const addCartItems = async () => {
//       const loggedin = !!localStorage.getItem('userToken');
//       const cartItems = localStorage.getItem('cartItems');
//       console.log("Checking whether cart items is present here : ",cartItems);
//       if (loggedin) {
//         const requestBody = {
//           items: [],
//           ompuserId,//get the id from the localStorage
//         customer_first_name,
//         customer_last_name,
//         customer_contact_no,
//         order_status: "Draft",
//         order_ref_number: "",
//         customer_email,
//         input: {
//           city: "",
//           cityArea: "",
//           companyName: "",
//           country: "IN",
//           countryArea: "",
//           firstName: "",
//           lastName: "  ",
//           phone: "",
//           postalCode: "",
//           streetAddress1: " ",
//           streetAddress2: "",
//         },
//         businessaddress: {
//           city: "",
//           cityArea: "",
//           companyName: " ",
//           country: "IN",
//           countryArea: "",
//           firstName: "",
//           lastName: "",
//           phone: "",
//           postalCode: "",
//           streetAddress1: "",
//           streetAddress2: "",
//         },
//         gst_no: "27AABCU9603T1ZV",
//         };
//         try {
//           // Fetch draft items
//           const draftResponse = await fetch(
//             'https://ompextension.origa.market/sparesapi/create_order',
//             {
//               method: 'POST',
//               headers: {
//                 'Content-Type': 'application/json',
//               },
//               body: JSON.stringify(requestBody),
//             }
//           );

//           if (!draftResponse.ok) {
//             throw new Error('Failed to fetch draft items');
//           }

//           const draftData = await draftResponse.json();
//           setCartItems(draftData.line_items || []);
//           setSubtotal(draftData.subtotal || 0);
//           setTaxTotal(draftData.tax_total || 0);
//           setDiscountTotal(draftData.discount_total || 0);
//           setDiscountPercent(draftData.discount_percent || 0);
//           setShippingCharge(draftData.shipping_charge || 0);
//           setTotal(draftData.total || 0);
//           setTotalPrice(draftData.total_quantity || 0);
//           setQuantity(draftData.total_quantity || 0);

//           // Calculate the total quantity from the response
//           const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
//           setTotalQuantity(totalQuantity);
//         } catch (error) {
//           console.error('Error fetching or merging cart items:', error);
//         }
//       } else {
//         setCartItems(localCartItems); // Use local cart items if not logged in
//       }
//     };
   
//     addCartItems(); // Call the function to add cart items on component mount
//   }, []);
      



//   useEffect(() => {
//     const handleCreateOrder = async () => {
//     const userToken = localStorage.getItem('userToken');
//     if (!userToken) {
//       // Handle case where user is not logged in
//       setLoginPortal(true); // Assuming this sets state to show login portal
//       return;
//     }
//     // console.log('cartItems --------------------------------------------------------------- > :', cartItems);
//     // console.log("cartItems -------------------------------->  ",cartItems)
//     const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
//     console.log("localCartItems :: ", localCartItems);
    
//     var itemList=[];
//     for(var item in localCartItems){
//       var obj={};
//       obj["item_id"] = localCartItems[item]["productId"];
//       obj["quantity"] = 1;
//       itemList.push(obj);
//     }
//     console.log("itemList :: ", itemList );
//     console.log("itemList :: ", itemList );
//     const ompuserId = localStorage.getItem("id") || "defaultId";
//     const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
//     const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
//     const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
//     const customer_email = localStorage.getItem("emailId") || "default@example.com";
    
//     const requestBody = {
    
//       items: itemList,
//       ompuserId,//get the id from the localStorage
//         customer_first_name,
//         customer_last_name,
//         customer_contact_no,
//         order_status: "Draft",
//         order_ref_number: "",
//         customer_email,
//         input: {
//           city: "",
//           cityArea: "",
//           companyName: "",
//           country: "IN",
//           countryArea: "",
//           firstName: "",
//           lastName: "  ",
//           phone: "",
//           postalCode: "",
//           streetAddress1: " ",
//           streetAddress2: "",
//         },
//         businessaddress: {
//           city: "",
//           cityArea: "",
//           companyName: " ",
//           country: "IN",
//           countryArea: "",
//           firstName: "",
//           lastName: "",
//           phone: "",
//           postalCode: "",
//           streetAddress1: "",
//           streetAddress2: "",
//         },
//         gst_no: "27AABCU9603T1ZV",
//     };
    
//     try {
//       console.log("requestBody  ------------------------------------------------------------- > :: ", requestBody);
//       // Create order request
//       const createOrderResponse = await fetch(
//         'https://ompextension.origa.market/sparesapi/create_order',
//         {
//           method: 'POST',
//           headers: {
//             Authorization: `Bearer ${userToken}`,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         }
//       );
    
//       if (!createOrderResponse.ok) {
//         throw new Error('Failed to create order');
//       }
    
//       const createOrderData = await createOrderResponse.json();
//       const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
//       console.log('Created order with items:', draftItems);
    
//       // Clear local storage cartItems after successful order creation
//       // localStorage.removeItem('cartItems');
//       setCartItems([]); // Clear cart items state
//     } catch (error) {
//       console.error('Error creating order:', error);
//     }
//     };
//     handleCreateOrder();
    
//     }, []);
    
    
// // 
  
  // Function to merge local items with draft items
  const mergeItems = (localItems, draftItems) => {
    const mergedItems = [...draftItems];
  
    localItems.forEach((localItem) => {
      const foundIndex = mergedItems.findIndex((item) => item.item_id === localItem.item_id);
      if (foundIndex !== -1) {
        mergedItems[foundIndex].quantity += localItem.quantity;
      } else {
        mergedItems.push(localItem);
      }
    });
  
    return mergedItems;
  };
  
  // Function to handle increasing quantity
  const handleIncreaseQuantity = async (index) => {
    if (index >= 0 && index < cartItems.length) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[index].quantity++;
      const ompuserId = localStorage.getItem("id") ;
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId") ;
      

      // Update the state
      setCartItems(updatedCartItems);

      // Prepare the request body
      const requestBody = {
        items: updatedCartItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
        })),
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
      };
      try {
        await fetch(
          "https://ompextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        console.log(
          "Quantity of this item id :  ",
          updatedCartItems[index].item_id
        );
        console.log(
          "Quantity of this item quantity :  ",
          updatedCartItems[index].quantity
        );
        window.location.reload();
      } catch (error) {
        console.error("Error updating quantity:", error);
        // Optionally, revert the state update in case of an error
      }
    } else {
      console.error("Invalid index:", index);
    }
  };

  const handleDecreaseQuantity = async (index) => {
    if (index >= 0 && index < cartItems.length) {
      const updatedCartItems = [...cartItems];
      if (updatedCartItems[index].quantity > 0) {
        updatedCartItems[index].quantity--;


        const ompuserId = localStorage.getItem("id") || "defaultId";
        const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
        const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
        const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
        const customer_email = localStorage.getItem("emailId") || "default@example.com";

        // Update the state
        setCartItems(updatedCartItems);

        // Prepare the request body
        const requestBody = {
          items: updatedCartItems.map((item) => ({
            item_id: item.item_id,
            quantity: item.quantity,
          })),
          ompuserId,//get the id from the localStorage
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "27AABCU9603T1ZV",
        };
        try {
          await fetch(
            "https://ompextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
          console.log(
            "Quantity of this item id :  ",
            updatedCartItems[index].item_id
          );
          console.log(
            "Quantity of this item quantity :  ",
            updatedCartItems[index].quantity
          );
          window.location.reload();
        } catch (error) {
          console.error("Error updating quantity:", error);
          // Optionally, revert the state update in case of an error
        }
      }
    } else {
      console.error("Invalid index:", index);
    }
  };
  const handleDelete = async (index) => {
    try {
      // Clone and update the cart items to set the quantity of the specified index to 0
      const updatedCartItems = [...cartItems];
      updatedCartItems[index].quantity = 0;

      // Update local state to reflect the quantity change
      setCartItems(updatedCartItems);

      const ompuserId = localStorage.getItem("id") || "defaultId";
      const customer_first_name = localStorage.getItem("firstName") || "DefaultFirstName";
      const customer_last_name = localStorage.getItem("lastName") || "DefaultLastName";
      const customer_contact_no = localStorage.getItem("number") || "DefaultContactNo";
      const customer_email = localStorage.getItem("emailId") || "default@example.com";
      

      // Prepare the request body to include all items, even those with a quantity of 0
      const requestBody = {
        items: updatedCartItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
        })),
        ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "IN",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "27AABCU9603T1ZV",
        };

      // Call the API to update the quantity
      const response = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      // Handle the response from the API if needed
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log(
        "Quantity of this item id: ",
        updatedCartItems[index].item_id
      );
      console.log(
        "Quantity of this item quantity: ",
        updatedCartItems[index].quantity
      );
      window.location.reload();
    } catch (error) {
      console.error("Error deleting item:", error);
      // Optionally, revert the state update in case of an error
    }
  };

  
  console.log("cartItems-------------------------->", cartItems);

  return (
    <>
    
      <div className="">
        {cartItems.map((item, index) => (
          <div key={index} className="threesectionsofcart">
            <div className="firstsectionofcart">
              <img
                className="imageonleftofcart"
                src={item.thumbnail && item.thumbnail[0]?.url}
                alt="Product"
              />
            </div>
            <div className="secondsectionofcart">
              <h1 className="headingpartofcarttt">{item.name}</h1>
              <div className="contentofcartpara">
                <p className="inStocktext">
                  <span className="inStocktextcontent">Tax:</span> ₹
                  {item.tax_total || "N/A"}
                  <br />
                  <span className="stockbold">SKU: {item.sku}</span>
                </p>
              </div>
              <div className="buttonsgapbetweentwocart">
                <span className="quantitytextright">
                  Quantity: {item.quantity}
                </span>
                <div className="quantity-selector-wrapper">
                  <div className="quantity-selector">
                    <button
                      onClick={() => handleDecreaseQuantity(index)}
                      className="quantity-button"
                    >
                      -
                    </button>
                    <span className="quantity-value">{item.quantity}</span>
                    <button
                      onClick={() => handleIncreaseQuantity(index)}
                      className="quantity-button"
                    >
                      +
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleDelete(index)}
                  className="deletecartbtn"
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="thirdsectionofcart">
              <p className="badgerightside">
                {/* <span className="badgeoffer"></span> */}
                {/* <span className="limitedtime">₹{item.rate}</span>
                <br /> */}
                <span className="salespricecart">₹{item.amount}</span>
              </p>
            </div>
          </div>
        ))}
        
      </div>
    </>
  );
};

export default CartItemSampleLoggedIn;

import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import {
    secondClient,
    GET_SPARE_DETAILS,
  } from "../../../OrigaExtentionAPI/mutations";
  import './QuotationPopupStyles.css';

const client = secondClient;
const QuotationPopup = ({ quantity, setQuantity, onSubmit }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get("id");
    const groupId = queryParams.get("group_id");
    const [loading, setLoading] = useState(true);
    const [productDETAILS, setProductDETAILS] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0); 
        const fetchData = async () => {
          let id = localStorage.getItem("id");
          if (id === null) {
            id = "";
          }
          try {
            const { data } = await client.query({
              query: GET_SPARE_DETAILS,
              variables: { groupId: groupId || "", itemId: productId || "" },
            });
            setProductDETAILS(data?.inventoryItem?.response);
            setLoading(false);
            updateFormattedPrice();
          } catch (error) {}
        };
        fetchData();
      }, [groupId, productId]);
    
      const updateFormattedPrice = () => {
        setProductDETAILS((prevDetails) => ({
         ...prevDetails,
          price_details: {
            ...prevDetails.price_details,
            machine_price: formatCurrency(prevDetails.sales_rate),
          },
        }));
      };
    
      const formatCurrency = (value) => {
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        }).format(value || 0);
      };

    return (
        <div className="totalpopupdiv">
    <h4 className="quotationDetailsheading">Quotation Details</h4>
    <div className="popupContentWrapper">
        <div className="Contentisnidepopup">
            <p className="">Name: {productDETAILS?.[0]?.name || "N/A"}</p>
            {/* <p>Price: {productDETAILS?.[0]?.saleprice || 0}</p> */}
            Quantity&nbsp;:&nbsp;
            <input type="number" style={{width: "30%"}} value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            <br />
            <button
                className="button-submit-popup"
                onClick={() => onSubmit({
                    productId: productDETAILS?.[0]?.id,
                    quantity
                })}
            >
                Submit
            </button>
        </div>
        <div className="imageWrapper">
            <img className="imageinpopup" src={productDETAILS[0]?.images?.productImages[0]?.url || "N/A"} alt="Product" />
        </div>
    </div>
</div>

      
    );
};


export default QuotationPopup;
import React, { useEffect } from 'react'
import "./heroComponent.css";
const HeroComponent = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    }, [])
  return (
   <>
  <div className='totalcustomerdiv'>
  <div className='left-side-customerdiv'>
    <div className='Companytext'>
    <img src='asset/image 6.png' className='imagelogoleftside' />
    <h3 className='textinp' >Transforming Industries with Sustainable Solutions.</h3>
    </div>
    <div className='button-left-side'>
    {/* <button className='Read-the-story-btn'>Read the Story</button> */}
    </div>
    
  </div>
  <div className='right-side-customerdiv'>
  <img className='right-side-customerimg' src='asset/Banner.webp' alt='Image' />
  </div>
 
</div>

<div className='howcustomerspara'>
<p className='howcustomerstext'>
See how our customers are thriving and expanding with Origa Market.</p>
</div>


   </>
  )
}

export default HeroComponent
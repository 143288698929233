import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { dollerIcon, tagIcon } from '../../../../helpers/Icons';

const MyOrdersPart = () => {
    const [loginPortal, setLoginPortal] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [isSize, setSize] = useState({ width: 38, height: 38 });
    const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [total, setTotal] = useState(0);
  const[totalprice,setTotalPrice] = useState(0);
  
  const navigate = useNavigate;

  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id") ;
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId") ;
      if (loggedin) {
        const requestBody = {
          items: [], // Initially empty as we'll fetch from API
          ompuserId,
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status:"",
          order_ref_number: "",
          customer_email,
        };
  
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );
  
          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }
  
          const draftData = await draftResponse.json();
          setCartItems(draftData.line_items || []);
         
          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0)
          let draftItems = draftData.line_items || []; // Assuming draft items are in line_items array
  
          // Merge localCartItems with draftItems based on item_id
          draftItems = mergeItems(localCartItems, draftItems);
  
          // Update cartItems state
          setCartItems(draftItems.slice(0, 3)); // Limit to 3 items
        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };
  
    addCartItems(); // Call the function to add cart items on component mount
  }, []);
  
  // Function to merge local items with draft items
  const mergeItems = (localItems, draftItems) => {
    const mergedItems = [...draftItems];
  
    localItems.forEach((localItem) => {
      const foundIndex = mergedItems.findIndex((item) => item.item_id === localItem.item_id);
      if (foundIndex !== -1) {
        mergedItems[foundIndex].quantity += localItem.quantity;
      } else {
        mergedItems.push(localItem);
      }
    });
  
    return mergedItems;
  };
const handleProceedPaymentbtn = () => {
  const loggedin = !!localStorage.getItem('userToken');
  if (loggedin) {
    handleCreateOrder();
    navigate('/manageaddresspart');
  } else {
    setLoginPortal(true);
  }
};


const handleCreateOrder = async () => {
const userToken = localStorage.getItem('userToken');
if (!userToken) {
  // Handle case where user is not logged in
  setLoginPortal(true); // Assuming this sets state to show login portal
  return;
}
const ompuserId = localStorage.getItem("id") ;
const customer_first_name = localStorage.getItem("firstName");
const customer_last_name = localStorage.getItem("lastName");
const customer_contact_no = localStorage.getItem("number") ;
const customer_email = localStorage.getItem("emailId") ;

const requestBody = {
  items: cartItems.map((item) => ({
    item_id: item.item_id,
    quantity: item.quantity,
  })),
  ompuserId,
  customer_first_name,
  customer_last_name,
  customer_contact_no,
  order_status:"",
  order_ref_number: "",
  customer_email,
};

try {
  // Create order request
  const createOrderResponse = await fetch(
    'https://ompextension.origa.market/sparesapi/create_order',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!createOrderResponse.ok) {
    throw new Error('Failed to create order');
  }

  const createOrderData = await createOrderResponse.json();
  const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
  console.log('Created order with items:', draftItems);

  // Clear local storage cartItems after successful order creation
  localStorage.removeItem('cartItems');
  setCartItems([]); // Clear cart items state
} catch (error) {
  console.error('Error creating order:', error);
}
};


  return (
    <>
      <div className="layout-wrap-machine">
        {cartItems.map((item, index) => (
          <div key={index} className="max-container detail-wrap">
            <div className="img-wrap-1">
              <img
                className="img-1"
                src={item.thumbnail[0]?.url || "N/A"} alt="Product" />
            </div>
            <div className="price-wrap">
              <div className="top">
              <div className="right bi-process-wrap">
    {/* Status 1: Started or Completed */}
    <span className="completed">{tagIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>

    {/* Status 2: Started (with additional content) or Completed */}
    <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>
    {/* <div className="status-content1 heading-400-12-10">Advance Paid</div> */}

    {/* Status 3: Started (with additional content) or Completed */}
    <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>
    {/* <div className="status-content2 heading-400-12-10">Financing</div> */}

    {/* Status 4: Started (with additional content) or Completed */}
    <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>
    {/* <div className="status-content3 heading-400-12-10">Full Payment</div> */}

    {/* Status 5: Started (with additional content) or Completed */}
    <span className="completed last-child-svg">{dollerIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>
    {/* <div className="status-content4 heading-400-12-10">Delivered</div> */}
</div>

              </div>
              <div className="bottom">
                <div className="left-wrap">
                  <div className="name heading-600-20 heading-600-20-16">
                  {item.name}
                  </div>
                  <div className="price">Price   : ₹{item.rate}</div>
                  <div className="price">Quantity   : {totalprice || 0}</div>
                  <div className="price">Shipping Charge: : ₹{shippingCharge}</div>
                </div>
                <div className="right-wrap">
                  <div className="advance-info">
                    <button
                      className="button heading-600-16-14"
                      type="button"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyOrdersPart;

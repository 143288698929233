import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./BlogPageStyles.css";
import { Helmet } from "react-helmet";

const KarkinosHealthcare = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    }, [])

    const blogPost = {
      heading: "Innovative leasing & financing solution for Karkinos Healthcare Pvt.Ltd",
    };

    
  return (
    <>
    <Helmet>
            <title>{blogPost.heading}</title>
            <meta
              name="keywords"
              content={`${blogPost.heading}`}
            />
          </Helmet>
      <div className="blogpage-totaldiv">
        {" "}
        {/* Total div to contain all divs inside :  */}
        <div className="blogpage-headingcontent">
          <h6 className="CaseStudy">Case Study</h6>
          <h1 className="CaeStudy-content">
          Innovative leasing &amp; financing solution for Karkinos Healthcare Pvt.Ltd
          </h1>
        </div>
        <div className="blogpage-profile">
          <div className="blogpage-profile-image">
            <img
              className="blogpage-profile-img-seperate"
              src="https://www.origa.market/asset/user.png"
            />
          </div>

          <div className="blogpage-profile-text">
            <p className="blogpage-para">
              <span>Origa</span>
              <span className="blogpage-year">July 5, 2024 • 2 min read</span>
            </p>
          </div>
        </div>
        <div className="blogpage-profile-bigimage">
          <img
            className="blogpage-profile-bigimage-seperate"
            src="/Karkinos Page.webp"
          />
          <p className="blogpage-profile-bigimage-text">
          Karkinos Healthcare Pvt.Ltd
          </p>
        </div>
        <div className="blogpage-realcontent">
          <h3 className="blogpage-realcontent-introductionheading">
            Background:
          </h3>
          <p className="blogpage-realcontent-introductioncontent">
          Karkinos Healthcare Pvt. Ltd, a purpose driven technology-led oncology platform, is focused
on designing and delivering bespoke solutions for cancer care. The company, led by a blend
of globally acclaimed medical professionals and technologists, is on a mission to create
‘cancer centres without walls’ with a primary aim of addressing the accessibility or
affordability gaps in cancer care.
          </p>

          {/* 
          <h3 className="blogpage-realcontent-introductionheading">Problem Statement:</h3>
          <p className="blogpage-realcontent-introductioncontent">
          In its pursuit of growth, Yasho Industries heavily relied on debt to finance its new projects.
          This strategy led to a substantial increase in its debt-to-equity ratio. However additional
          capital was required to fund its expansion.
          Recognizing the critical need for a sustainable financing solution, the company explored
          alternative financial strategies. It approached ORIGA, a company known for innovative
          leasing &amp; financing solutions, to understand how it could assist in its expansion.
          </p> */}

          <h3 className="blogpage-strategic-objectives">Problem Statement:</h3>
          <p className="blogpage-realcontent-Strategicpara">
            Karkinos HealthCare aimed to achieve  key objectives with this campaign:
          </p>

          <ul className="blogpage-unorderd">
          <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Objective:</span>{" "}
              Secure additional funding for expansion and operational activities without further diluting equity.
            </li>
            <li className="blogpage-list dljgn">
              {" "}
              <span className="blogpage-boost">Initial Capital Allocation: </span>Raised significant equity capital to purchase advanced research equipment.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">
              Challenge: 
              </span>{" "}
              Blocking of capital, limiting ability to expand and cover operational expenses.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Strategic Goal: </span> Develop a sustainable financial strategy to balance capital expenditures (CapEx) and operational expenditures (OpEx).
            </li>
          </ul>

          <h3 className="blogpage-realcontent-innovateheading">
            Origa’s Innovative Leasing And Financing Solution:
          </h3>
          <p className="blogpage-realcontent-innovativecontent">
          Origa undertook a detailed study of company’s financial position and understood its
strengths and constrains. It then introduced Karkinos to the concept of leasing, emphasising
the benefits of off-balance-sheet financing.
Origa proposed a mixed approach that utilized both leasing and loan facilities. The leasing
strategy not only freed up its equity blocked in the equipment but also provided for additional
expansion funding.
          </p>

          <h4 className="blogpage-realcontent-leasingstructureheading">
          ORIGA stitched together a consortium of leasing companies as well as debt financiers
to the tune of INR 432 Million in just two months and structured a unique transaction that
met the requirements of the company effectively.
          </h4>

          <div className="blogpage-realcontent-leasingstructureparagrapdiv">
         
              <p className="blogpage-realcontent-leasingstructureparagrap">
              With a clear financial strategy and better allocation of resources, Karkinos was able to scale
              their operations and invest in crucial research and development activities.
              </p>
          </div>

          <h3 className="blogpage-realcontent-conclusion">Conclusion:</h3>

          <p className="blogpage-realcontent-conclusionparagraph">
          By leveraging the expertise the deep domain expertise of Origa, Karkinos transformed its
financial strategy, ensuring sustainable growth and operational efficiency. The case
highlights how a combination of debt and leasing can optimize capital utilization, driving
expansion and innovation in mission-critical sectors like cancer research.
Origa advised the company in all the areas of the transaction such as working out the right
lease structure, pricing discussion, appropriate documentation and transaction closure.
          </p>
        </div>
      </div>

      <div className="lastline">
        <hr className="lastlinehr" />
      </div>

      <div className="somespacetop"></div>
      <Footer />
    </>
  );
};

export default KarkinosHealthcare;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Card from "./Card";

const CardGrid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cards = [
    {
      imgSrc: "asset/Yasho card.webp",
      label: "Case Study",
      heading: "Yasho Industries Limited ",
      logoSrc:"asset/yash_logo.webp",
      text:
        "ORIGA’S innovative leasing strategy for Yasho Industries Limited growth initiatives.",
      slug: "origa-innovative-leasing-strategy",
    },
    {
      imgSrc: "asset/Manipal card .webp",
      label: "Case Study",
      heading: "Manipal’s MCT Card and Technologies Pvt Ltd",
      logoSrc:"asset/MCT_logo.webp",
      text:
        "Manipal and Origa’s Strategic Leasing Partnership for Business Expansion",
      slug: "manipal-origa-stragetic",
    },
    {
      imgSrc: "asset/Karkinos card.webp",
      label: "Case Study",
      heading: "Karkinos Healthcare Pvt.Ltd",
      logoSrc:"asset/karkinos.webp",
      text:
        "Innovative leasing & financing solution for Karkinos Healthcare Pvt.Ltd",
      slug: "karkinos-healthcare",
    },
    
  ];

  return (
    <div className="wrapper">
      <div className="cards">
        {cards.map((card, index) => (
          <div key={index}>
            {/* <Helmet>
              <title>{card.heading}</title>
            </Helmet> */}
            
            <Card
              imgSrc={card.imgSrc}
              label={card.label}
              logoSrc={card.logoSrc}
              heading={card.heading}
              text={card.text}
              slug={card.slug}
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
